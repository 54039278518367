<template>
  <div v-loading="!entity.BillId" :style="{ background: Status == 3 ? 'rgb(255 239 239)' : '' }">
    <!--    j加载提示框-->
    <div v-if="loading" style="
        height: 100vh;
        position: absolute;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 99;
        background-color: #fff;
      ">
      <img src="../../assets/loading.gif" style="width: 150px" />
    </div>
    <div v-if="isMobiles" style="height: 30px"></div>
    <div style="display: flex">
      <div id="printDiv" :style="`transition:all 0.3s; width: ${styleW}cm;pointer-events:none`" ref="pdfContent">
        <div v-for="(text, wIndex) in newarr" :key="wIndex" :style="`position: relative;width: ${text.styleW}cm;height: ${styleH * (Number(text.printPages) > 1 ? Number(text.printPages) : 1)
          }cm;page-break-after: always`">
          <div style="float: right; margin: 10px 100px 0">
            第{{ wIndex + 1 }}/{{ newarr.length }}页
          </div>
          <vue-drag-resize v-for="(item, index) in text.printlist" :key="item.Id" :is-active="false"
            :preventActiveBehavior="false" :isDraggable="false" :isResizable="false" :aspectRatio="false"
            parentLimitation :parentW="getpx(text.styleW, 'w', 'px')" :parentH="getpx(
              styleH *
              (Number(text.printPages) > 1 ? Number(text.printPages) : 1),
              'h',
              'px'
            )
              " :w="item.w" :h="item.h" :x="item.x" :y="item.y" :minh="28" :minw="80">
            <div v-if="item.type === '7'">
              <vue-qr :margin="0" :text="codeValue" :logoScale="0.2" :size="item.w"></vue-qr>
            </div>
            <div v-if="item.type === '1' || item.type === '12'"
              :style="`width: 100%;height: ${item.lineHeight}px;line-height: ${item.lineHeight}px;font-size: ${item.fontSize}px;font-weight:${item.bold}; flex-wrap: nowrap;`">
              <p v-if="item.Keys !== 'countnums' && item.Keys !== 'wights'">
                {{ item.value }}
              </p>
            </div>
            <div v-if="item.type === '11'"
              :style="`width: 100%;height: ${item.lineHeight}px;line-height: ${item.lineHeight}px;font-size: ${item.fontSize}px;font-weight:${item.bold}; flex-wrap: nowrap;`">
              <!-- //数量合计 -->
              <p v-if="item.Keys === 'countnums'">
                {{ item.Key }}{{ countnumsfun(text.printlist) }}
              </p>
              <!-- 价格合计 -->
              <p v-if="item.Keys === 'SumiInvRCost'">
                {{ item.Key }}{{ priceNums(text.printlist) }}
              </p>
              <!-- 价格总计 -->
              <p v-if="item.Keys === 'SumiInvACosts'">
                {{ item.Key }}{{ TotalSum || 0 }}
              </p>

              <!-- 重量合计 -->
              <p v-if="item.Keys === 'wights'">
                {{ item.Key }}{{ wightsfun(text.printlist) }}
              </p>
              <!-- 实收数量 -->
              <p v-if="item.Keys === 'signnums'">
                {{ item.Key }}{{ realNums(text.printlist) }}
              </p>
              <!-- 重量合计- 石家庄辰泰专用 -->
              <p v-if="item.Keys === 'totalweights'">
                {{ item.Key }}{{ getTotalWeight() }}
              </p>
              <!-- 数量合计- 石家庄辰泰专用 -->
              <p v-if="item.Keys === 'totalnumbers'">
                {{ item.Key }}{{ getTotalNumber() }}
              </p>

              <!-- 重量小计- 石家庄辰泰专用 -->
              <p v-if="item.Keys === 'subtotalweights'">
                {{ item.Key
                }}{{ subtotalweightsHandle(text.printlist, wIndex) }}
              </p>
              <!-- 数量小计- 石家庄辰泰专用 -->
              <p v-if="item.Keys === 'subtotalnums'">
                {{ item.Key }}{{ subtotalnumsHandle(text.printlist, wIndex) }}
              </p>

              <p v-if="item.Keys === 'IdCard'">{{ item.Key }}{{ IdCard }}</p>
              <p v-if="item.Keys === 'ReceiptPhone'">
                {{ item.Key }}{{ ReceiptPhone }}
              </p>
              <p v-if="item.Keys === 'FullName'">
                {{ item.Key }}{{ FullName }}
              </p>
              <p v-if="item.Keys === 'SignInAddress'">
                {{ item.Key }} {{ item.value }}
              </p>
              <p v-if="item.Keys === 'Memo'">
                {{ item.Key }}{{ item.value || Memo }}
              </p>
              <!-- 实收重量 -->
              <p v-if="item.Keys === 'signweights'">
                {{ item.Key }}{{ signweights(text.printlist) }}
              </p>
              <p v-if="item.Keys === 'SigningDate'">
                {{ item.Key
                }}{{ SigningDate ? SigningDate.substring(0, 10) : "" }}
              </p>
            </div>

            <div v-if="item.type === '5'"
              :style="`width: 100%;height: ${item.lineHeight}px;line-height: ${item.lineHeight}px;font-size: ${item.fontSize}px;font-weight:${item.bold}; flex-wrap: nowrap;`">
              <!-- <div
              v-if="item.Key === '制单人'"
              style="display: flex; flex-wrap: nowrap"
            >
              <p>{{ item.value }}</p>
              <img class="seal" :src="Seal" alt="" width="130" height="130" />
            </div> -->
              <!-- 多个签收人 -->
              <!-- <div v-if="isnew"> -->
              <div>
                <div v-if="item.Keys === 'Signs'" style="display: flex; flex-wrap: nowrap">
                  <p style="margin-right: 40px">
                    {{ item.Key }}
                  </p>
                  <div class="SginImgBox" style="flex: 1">
                    <img class="SginImg" v-show="Sign1" :src="Sign1" alt="" />
                  </div>
                </div>
                <div v-if="item.Keys === 'Sign1'" style="display: flex; flex-wrap: nowrap">
                  <p style="margin-right: 40px">
                    {{ item.Key }}
                  </p>
                  <div class="SginImgBox" style="flex: 1">
                    <img class="SginImg" v-show="Sign" :src="Sign" alt="" />
                  </div>
                </div>
              </div>
              <!-- 单个签收人 -->
              <!-- <div v-else>
                <div v-if="item.Keys === 'Sign1'" style="display: flex; flex-wrap: nowrap" >
                  <p style="margin-right: 40px">
                    {{ item.Key }}
                  </p>
                  <div class="SginImgBox" style="flex: 1">
                    <img class="SginImg" v-show="Sign1" :src="Sign1" alt="" />
                  </div>
                </div>
                <div v-if="item.Keys === 'Sign'" style="display: flex; flex-wrap: nowrap" >
                  <p style="margin-right: 40px">
                    {{ item.Key }}
                  </p>
                  <div class="SginImgBox" style="flex: 1">
                    <img class="SginImg" v-show="Sign" :src="Sign" alt="" />
                  </div>
                </div>
              </div>-->
            </div>

            <div
              :style="`display: flex;width: 100%;height: ${item.lineHeight}px;line-height: ${item.lineHeight}px;font-size: ${item.fontSize}px;font-weight:${item.bold}; flex-wrap: nowrap;`"
              v-if="item.type === '2' || item.type === '6'">
              <!-- <p v-if="item.Key === '发货方'">
                {{ item.Key }} {{ CompanyName }}
              </p> -->
              <div style="height: 100%" :ref="item.key + item.Id">
                {{ item.Key }}&nbsp;
              </div>
              <div :style="`flex:1;height: 100%;border-bottom: 1px solid #000;text-align: ${item.align};`">
                <span v-if="item.Key === '发货方'">
                  {{ CompanyName }}
                </span>
                <span v-else-if="item.Keys === 'Memo'">
                  {{ item.value || Memo }}</span>
                <span v-else> {{ item.value }}</span>
              </div>
            </div>

            <div v-if="item.type === '3'" style="width: 100%; height: 100%">
              <!--              <img :src="logo64" alt="" width="100%" height="100%" />-->
              <img :src="item.value" alt="" width="100%" height="100%" />

              <!-- <img
              style="transform: rotate(-90deg)"
              :src="Sign"
              alt=""
              width="100%"
              height="100%"
              v-if="item.Key === '手签'"
            /> -->
            </div>

            <div v-if="item.type === '4'" style="width: 100%; height: 100%">
              <table border="1" style="width: 100%">
                <thead style="background-color: #ccc">
                  <td v-for="(val, index) in item.columns" :key="val.dataIndex" :style="`width: ${val.width || 50}px;line-height: ${val.lineHeight || 28
                    }px; height: ${val.lineHeight || 28}px; text-align: ${val.align || 'center'
                    };font-weight:${val.bold || 'initial'};font-size:${val.fontSize || 18
                    }px;`">
                    {{ val.title }}
                  </td>
                </thead>

                <tr v-for="(itemdata, index) in item.datalist" :key="index">
                  <td v-for="(val, valix) in item.columns" :key="valix"
                    :style="`text-align: ${val.textalign || 'center'};`">
                    <div v-if="val.dataIndex == 'Number'" :style="`text-align: ${val.textalign || 'center'
                      };height:${val.textlineHeight || 20
                      }px;overflow: hidden;word-break:break-all;`">
                      {{ itemdata[val.dataIndex] }}
                    </div>

                    <div v-else-if="val.dataIndex == 'SumiInvRCost'">
                      {{
                        itemdata[val.dataIndex] &&
                        toThousands(itemdata[val.dataIndex])
                      }}
                    </div>
                    <!-- <div
                      v-else-if="val.dataIndex == 'cInvStd'"
                      :style="`width:150px;text-align: ${
                        val.textalign || 'center'
                      };height:${
                        val.textlineHeight || 20
                      }px;overflow: hidden;word-break:break-all;`"
                    >
                      {{ itemdata[val.dataIndex] }}
                    </div> -->
                    <div v-else :style="`text-align: ${val.textalign || 'center'
                      };height:${val.textlineHeight || 20
                      }px;overflow: hidden;word-break:break-all;`">
                      {{ resetValue(val, itemdata) }}
                    </div>
                  </td>
                </tr>
              </table>
            </div>
            <div v-if="item.type === '8'" style="width: 100%; height: 100%">
              <p :style="`background: #000; width: 100%; height: ${item.pxh}px`"></p>
            </div>
            <div v-if="item.type === '9'" style="width: 100%; height: 100%">
              <p :style="`background: #000; width: ${item.pxh}px; height: 100%`"></p>
            </div>
            <div v-if="item.type === '10'" style="width: 100%; height: 100%">
              <div :style="`width: 100%; height: 100%; border: ${item.pxh}px solid #000`"></div>
            </div>
          </vue-drag-resize>
        </div>
        <div v-if="isAccessory && imglist.length" style="margin-top: 100px">
          <el-row>
            <el-col v-for="item in imglist" :span="8" :key="item" style="margin-bottom: 10px">
              <el-image style="width: 200px; height: 200px; border: 1px solid #ccc" :src="item" fit="contain"
                :preview-src-list="imglist">
              </el-image>
            </el-col>
          </el-row>
        </div>
      </div>
      <!-- <div class="tool" :class="{ 'pctool': isMobiles }">
        <el-switch v-model="showEmpty" @change="handleShowEmpty" active-text="显示空行" inactive-text="隐藏空行">
        </el-switch>

        <el-switch v-if="isMobiles" v-model="showBaseWidth" @change="handleShowBaseWidth" active-text="适应屏幕"
          inactive-text="原始尺寸">
        </el-switch>
      </div> -->
      <div v-if="Status == -1" style="padding-top: 20px; padding-left: 20px">
        <el-button @click="openEditForm">编辑</el-button><br />
        <el-button @click="confirmForm">确认</el-button>
      </div>
      <div v-if="TimeStamp" style="padding-top: 20px; padding-left: 20px">
        <div v-if="false" class="rightMain" :style="{ color: $route.query.TimeStamp ? '#2f98c7' : '' }">
          <div><b>存证编号: </b>{{ entity.Hash }}</div>
          <div v-if="TimeStamp">
            <b>存证日期: </b>{{ timestampToTime($route.query.TimeStamp) }}
          </div>
        </div>
        <div v-show="entity.ReceiptPhone" class="rightMain">
          <h3 style="text-align: center; font-weight: bold">签收人信息</h3>
          <div><b>签收人: </b>{{ entity.FullName }}</div>
          <div><b>认证日期: </b>{{ entity.SigningDate }}</div>

          <div><b>手机号: </b>{{ entity.ReceiptPhone }}</div>
          <!-- <div><b>身份证: </b>{{ idCardPass(entity.IdCard) }}</div> -->
          <div><b>身份证: </b>{{ entity.IdCard }}</div>
          <div><b>设备: </b>{{ entity.DeviceName }}</div>
          <div v-if="entity.IP">
            <b>{{ entity.IP.indexOf(".") !== -1 ? "IP: " : "设备ID: " }}</b>{{ entity.IP }}
          </div>
          <div><b>签收地址: </b>{{ entity.SignInAddress }}</div>
        </div>
        <div>
          <h3 style="text-align: center; font-weight: bold">签收</h3>
          <el-timeline>
            <el-timeline-item v-for="(activity, index) in timeList" :key="index" :timestamp="activity.CreateTime">
              <span>类型: {{ activity.Type }}</span>
              <br />

              <b>{{ activity.Creator }} {{ activity.Phone }}</b>
              <br />
              {{ activity.Action }}
              <br />
              <span v-show="activity.IP">IP: {{ activity.IP }}</span>
            </el-timeline-item>
          </el-timeline>
        </div>
      </div>
    </div>
    <div v-html="styleText"></div>
    <div v-if="videoSrc" style="width: 100%; height: 100%">
      <video style="width: 200px; height: 200px; z-index: initial" controls :src="videoSrc" autoplay="false"></video>
    </div>
    <div style="height: 80px"></div>
    <div v-if="!isSign">
      <div v-if="Status != -1 && !TimeStamp" class="printBox" :class="{ 'mobile-botton': isMobiles }" :style="{
        background: Status == 3 ? 'rgb(255 239 239)' : '',
        minWidth: isMobiles ? '' : styleW + 'cm',
      }">
        <!-- <el-dropdown placement="top" trigger="click">
          <span class="el-dropdown-link">
            <el-button type="primary">选择模板</el-button>
          </span>
          <el-dropdown-menu slot="dropdown" v-loading="TemplateLoading">
            <el-dropdown-item v-for="(item) in template" :key="item.Id" :command="item.Id">{{ item.TemName
              }}</el-dropdown-item>

          </el-dropdown-menu>
        </el-dropdown> -->
        <el-popover placement="top" width="230" trigger="click" v-model="istemplate">
          <div>当前模板:{{ ContentNameChange }}</div>
          <div style="max-height: 300px; overflow: auto; width: 200px" v-loading="TemplateLoading">
            <div v-for="item in template" :key="item.Id" style="
                cursor: pointer;
                margin-top: 5px;
                background-color: #fff;
                border: 1px solid #ebeef5;
                border-radius: 4px;
                box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
                line-height: 30px;
                padding: 10px;
                text-align: center;
              " @click="clickTemplate(item)">
              {{ item.TemName }}
            </div>
            <el-empty description="暂无数据" v-if="!template.length"></el-empty>
          </div>

          <el-button class="bottom-botton" :size="isMobiles ? 'mini' : ''" slot="reference"
            type="primary">选择模板</el-button>
        </el-popover>

        <el-button style="margin-left: 10px" class="bottom-botton" :size="isMobiles ? 'mini' : ''" v-if="Status == 0"
          type="primary" :disabled="disabled" icon="el-icon-chat-line-round" @click="sendSMS('发送签收链接')">发送短信</el-button>

        <el-button class="bottom-botton" :size="isMobiles ? 'mini' : ''" v-if="Status == 0" type="primary"
          :disabled="disabled" icon="el-icon-chat-line-round" @click="handleCommand('单据信息')">微信分享</el-button>
        <!--        暂时注释 后续需要再用-->
        <!--        <el-button :size="isMobiles ? 'mini' : ''" type="primary" style="margin-left: 10px" icon="el-icon-share"-->
        <!--          @click="handleCommand('单据信息')">微信分享</el-button>-->
        <!-- <el-dropdown trigger="click" @command="handleCommand">
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item command="单据信息">分享回执信息</el-dropdown-item>
          <el-dropdown-item command="签收码">分享签收码</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown> -->

        <el-button :size="isMobiles ? 'mini' : ''" type="primary" icon="el-icon-printer" v-print="printObj"
          style="margin-left: 10px">打印货单</el-button>
        <el-button :size="isMobiles ? 'mini' : ''" type="primary" icon="el-icon-printer" style="margin-left: 10px"
          @click="dialogTwoVisible = true">打印签收码</el-button>
        <!-- <el-button
          type="primary"
          icon="el-icon-download"
          style="margin-left: 10px"
          @click="handleDownload"
          >下载PDF</el-button
        > -->
        <el-button :size="isMobiles ? 'mini' : ''" :type="pdfLoading ? 'warning' : 'primary'"
          :icon="pdfLoading ? 'el-icon-loading' : 'el-icon-download'" :disabled="pdfLoading" style="margin-left: 10px"
          @click="handleDownload">
          {{ pdfLoading ? "生成" : "下载" }}PDF
        </el-button>

        <!-- <el-dropdown placement="top" trigger="click" @command="dropdownclick" style="margin-left: 10px"
          v-if="IsSubAccount">
          <el-button type="primary" icon="el-icon-printer" @click="butprintclick">
            货品打印
          </el-button>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item v-for="item in templatelist" :key="item.Id" :command="item">{{ item.TemName
              }}</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown> -->
        <!-- <el-button
        type="primary"
        style="margin-left: 10px"
        icon="el-icon-download"
        @click="creditFile"
        v-if="Status == 1 || Status == 2"
      >
        下载存证
      </el-button> -->
      </div>
    </div>
    <div v-if="isSign">
      <div class="printBox sign">
        <el-link v-if="href" :href="href" target="_blank" style="
            width: 200px;
            height: 40px;
            background-color: #32cfaa;
            color: #fff;
          ">去签收</el-link>
        <!-- <el-button type="primary" style="width: 200px">
          <el-link :href="href" target="_blank" style="color: #fff"
            >去签收</el-link
          >
        </el-button> -->
      </div>
    </div>
    <!-- 微信分享 -->
    <el-dialog :title="'请使用微信扫一扫'" :visible.sync="dialogVisible" width="300px" :before-close="handleClose">
      <div style="display: flex; justify-content: center; flex-wrap: wrap">
        <!-- <div>当前模板：{{ ContentNameChange }}</div> -->
        <vue-qr :logoSrc="require('@/assets/logo.png')" :text="codeValue" :logoScale="0.2" :size="200"></vue-qr>
      </div>
    </el-dialog>
    <!-- 随货同行签收码 -->
    <el-dialog title="随货同行签收码" :visible.sync="dialogTwoVisible" width="300px" :before-close="() => {
      dialogTwoVisible = false;
    }
      ">
      <div style="text-align: center; color: #000; font-size: 14px">
        <div id="printTwo">
          <div style="display: flex; justify-content: center">
            <vue-qr :text="codeValue" :logoScale="0.2" :size="130"></vue-qr>
          </div>
          <div style="text-align: left; margin-left: 30px">
            单号:<span>{{ danhao }}</span>
          </div>
          <div style="text-align: left; margin-left: 30px">
            日期: <span>{{ cTime }}</span>
          </div>
        </div>
        <!--        暂时去掉-->
        <!--        <div style="text-align: left; margin-left: 30px">-->
        <!--          授权码:<span>{{ smsCode }}</span>-->
        <!--        </div>-->
        <!--        <div style="text-align: left; margin-left: 30px">-->
        <!--          验证码:<span>{{ SignCode }}</span>-->
        <!--        </div>-->
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogTwoVisible = false">取 消</el-button>
        <el-button type="primary" v-print="printTwo" @click="dialogTwoVisible = false">打 印</el-button>
      </span>
    </el-dialog>
    <!-- 货品打印 -->
    <el-drawer title="货品打印" :visible.sync="dialogGoods" destroy-on-close size="50%" :before-close="handleClose">
      <template slot="title">
        <div style="display: flex; align-items: center">
          <div>货品打印</div>
          <el-select style="display: inline-block; width: 200px; margin-left: 20px" v-if="data[0]" v-model="cInvStd"
            @change="datalistchange">
            <el-option v-for="(item, index) in data[0].datalist" :key="index" :label="item.cInvName" :value="item.Id">
            </el-option>
          </el-select>
        </div>
      </template>

      <!-- style="
          display: flex;
          justify-content: center;
          flex-direction: column;
          align-items: center;
        " -->
      <div id="printGoodsObj" v-loading="drawerloading">
        <div v-for="(val, inde) in printhuopinlist" :key="val.Id + '-' + inde"
          :style="`width: ${printopenobj.styleW}cm;height: ${printopenobj.styleH}cm; position: relative;border:1px solid #ccc;margin:auto;color:#000`">
          <vue-drag-resize v-for="(item, index) in printopenobj.printlist" :key="item.Id" :isActive="false"
            :preventActiveBehavior="false" :isDraggable="false" :isResizable="false" :aspectRatio="false"
            parentLimitation :parentW="getpx(printopenobj.styleW, 'w', 'px')" :parentH="getpx(
              styleH *
              (Number(printopenobj.printPages) > 1
                ? Number(printopenobj.printPages)
                : 1),
              'h',
              'px'
            )
              " :w="item.w" :h="item.h" :x="item.x" :y="item.y" :minh="28" :minw="80">
            <div v-if="item.type === '1'"
              :style="`width: 100%;height: ${item.lineHeight}px;line-height: ${item.lineHeight}px;font-size: ${item.fontSize}px;font-weight:${item.bold}; flex-wrap: nowrap;`">
              <p>{{ item.Key }}&nbsp;{{ val[item.Keys] }}</p>
            </div>
            <div v-if="item.type === '2'" style="width: 100%; height: 100%; border: 3px solid red">
              <!-- <div style="text-align: center">{{ item.Key }}</div> -->
              <vue-qr v-if="item.Keys === 'qrcode'" :margin="0" :text="qrcodetext(item.Id, val.codeList)"
                :logoScale="0.2" :size="item.w"></vue-qr>
              <VueBarcode :ref="'barcode' + item.Id" v-if="item.Keys === 'barcode'" :margin="0"
                :value="qrcodetext(item.Id, val.codeList)" :height="item.height || 30" :width="2" lineColor="#000"
                fontOptions="bold" elementTag="img" class="barcode"></VueBarcode>
            </div>
          </vue-drag-resize>
        </div>
      </div>
      <div style="height: 105px"></div>
      <div :style="{
        position: 'absolute',
        right: 0,
        bottom: 0,
        width: '100%',
        borderTop: '1px solid #e9e9e9',
        padding: '5px 16px',
        background: '#fff',
        textAlign: 'right',
        zIndex: 99999,
      }">
        <el-pagination style="margin-left: 10px; margin-bottom: 10px; float: left" @size-change="handleSizeChange"
          @current-change="handleCurrentChange" :current-page="pagination.PageIndex" :page-sizes="pagination.pageSizes"
          :page-size="pagination.PageRows" layout="total, sizes, prev, pager, next" :total="pagination.total">
        </el-pagination>
        <el-button @click="dialogGoods = false">取消</el-button>
        <el-button type="primary" v-print="printGoodsObj">打印</el-button>
      </div>
    </el-drawer>

    <!-- 客户手机号 -->
    <el-dialog title="客户手机号" :visible.sync="PhoneVisible" width="300px">
      <el-input v-model="phone" placeholder="请输入客户手机号" autocomplete="off"></el-input>
      <div slot="footer" class="dialog-footer">
        <el-button @click="PhoneVisible = false">取 消</el-button>
        <el-button type="primary" @click="PhoneConfirm">确 定</el-button>
      </div>
    </el-dialog>
    <EditForm ref="waybillForm" :parentObj="{ ...this }" />

    <!-- 发送验证码弹出框 -->

    <div class="tool" :class="{ pctool: isMobiles }" v-if="isMobiles">
      <el-button icon="el-icon-zoom-in" class="tool-button left" @click="pagesizeSwitchChange(false)"></el-button>
      <el-button icon="el-icon-zoom-out" class="tool-button right" @click="pagesizeSwitchChange(true)"></el-button>
    </div>
  </div>
</template>

<script>
const regExp = new RegExp("^1[3456789]\\d{9}$"); // 手机号正则
import EditForm from "../waybill/waybillForm.vue";
import VueBarcode from "vue-barcode";
import QRCode from "qrcodejs2";
import VueQr from "vue-qr";
import wx from "weixin-js-sdk";
import { toThousands, isMobile } from "@/utils/Tools";
import { downloadPDF } from "../../utils/htmlToPdfSign"; //@/utils/htmlToPdf
import { pc2phone } from "@/utils/pc2phone.js";
import pdfapis from "../../config/pdfapigroup";
const NumFormatting = function (data, num = 2) {
  if (isNaN(Number(data))) {
    return data;
  } else {
    return Number(data).toFixed(num);
  }
};

const downloadFile = (url, downloadName = "") => {
  // console.log(url);
  const link = document.createElement("a");
  fetch(url)
    .then((res) => res.blob())
    .then((blob) => {
      link.href = URL.createObjectURL(blob);
      // console.log(link.href)
      link.download = downloadName + ".pdf";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    });
};
function jsgetDPI() {
  var arrDPI = new Array();
  if (window.screen.deviceXDPI != undefined) {
    arrDPI[0] = window.screen.deviceXDPI;
    arrDPI[1] = window.screen.deviceYDPI;
  } else {
    var tmpNode = document.createElement("DIV");
    tmpNode.style.cssText =
      "width:1in;height:1in;position:absolute;left:0px;top:0px;z-index:99;visibility:hidden";
    document.body.appendChild(tmpNode);
    arrDPI[0] = parseInt(tmpNode.offsetWidth);
    arrDPI[1] = parseInt(tmpNode.offsetHeight);
    tmpNode.parentNode.removeChild(tmpNode);
  }
  return arrDPI;
}
export default {
  components: {
    VueQr,
    VueBarcode,
    EditForm,
  },
  computed: {
    ContentNameChange() {
      let obj = this.template.find((item) => {
        return item.Id == this.ContentId;
      });
      if (obj) {
        return obj.TemName;
      } else {
        return "";
      }
    },
  },
  data() {
    return {
      // 认证方式
      SignType: "",
      //   发送方式
      SendType: "",
      loading: false,
      // 二维码弹窗标题
      dialogTitle: "(H5分享)",
      pdfapis,
      logo64: "",
      CompanyName: "",
      CustName: "",
      isnew: false,
      href: "",
      template: [], //当前公司模板列表
      istemplate: false,
      TemplateLoading: false,
      isSign: false,
      CompanyLoading: false,
      Status: 0,
      IsSubAccount: false,
      drawerloading: false,
      templatelist: [],
      isAccessory: null,
      imglist: [],
      newarr: [],
      hasEmptyList: [],
      QrCode: "",
      qrcodemodel: {}, //二维码模板,
      barcodemodel: {}, //二维码模板,
      countnums: "",
      wights: "",
      totalweights: "",
      totalnumbers: "",
      printValue: "",
      form: {},
      entity: {},
      data: [],
      allNum: 0,
      allWeight: 0,
      Sign: "", //签名
      Sign1: "", //签名2
      showEmpty: "0",
      Seal: "",
      videoSrc: "",
      printObj: {
        id: "printDiv",
        popTitle: "",
      },
      printGoodsObj: {
        id: "printGoodsObj",
        popTitle: "",
      },
      printTwo: {
        id: "printTwo",
        popTitle: "",
      },
      billid: "",
      phone: "",
      oldDatalist: [],
      code: "",
      dialogVisible: false,
      codeValue: "",
      sendMessage: "",
      dialogGoods: false,
      printId: "",
      dialogPrintVisible: false,
      printData: [], // 所有要展示的打印数据
      showPrintData: [], // 当前展示的打印数据
      printlist: [],
      printPages: "",
      styleW: "",
      styleH: "",
      printsize: "",
      styleText: "",
      printopenobj: {},
      printopenlist: [],
      qrendbar: "",
      Company: "",
      printhuopinlist: [],
      cInvStd: "",
      arrprint: [],
      FullName: "",
      PhoneVisible: false,
      ReceiptPhone: "",
      IdCard: "",
      pagination: {
        PageIndex: 1,
        PageRows: 10,
        total: 0,
        pageSizes: [10, 20, 30, 50],
      },
      timer: null,
      oneMinute: 60,
      twoMinute: 60,
      templateN: {},
      disabled: false,
      urlDisabled: false,
      codeTwo: "",
      dialogTwoVisible: false,
      danhao: "",
      cTime: "",
      smsCode: "",
      timeList: [],
      TimeStamp: "",
      SignCode: "",
      Content: "", //当前模板
      ContentId: "", //当前模板Id
      ContentName: "", //当前模板name
      TotalSum: 0, // 整单合计
      pdfLoading: false, //下载pdf
      isMobiles: isMobile(),
      showBaseWidth: true,
      uPhone: "",
      Memo: "",
      pageScale: true,
      sumTotal: 0,
    };
  },
  async created() {
    this.loading = true;
    console.log("获取waybillcoy页面传递过来得行数据=====", this.$route.query);
    var obj = JSON.parse(sessionStorage.getItem("userInfo"));
    if (this.$route.query.token) {
      sessionStorage.setItem("token", this.$route.query.token);
    }
    if (this.$route.query.TimeStamp) {
      this.TimeStamp = this.$route.query.TimeStamp;
    }
    if (this.$route.query.uid) {
      let result = await this.$http.post("/Base_Manage/Base_User/GetTheData", {
        id: this.$route.query.uid,
      });
      // 免费及非主账户不展示
      if (
        result.Success &&
        result.Data &&
        !result.Data.IsSubAccount &&
        result.Data.AccountType != "免费"
      ) {
        this.IsSubAccount = true;
      } else {
        this.IsSubAccount = false;
      }
    } else if (obj) {
      if (obj.AccountType === "免费") {
        this.IsSubAccount = false;
      } else {
        if (!obj.IsSubAccount) {
          this.IsSubAccount = true;
        } else {
          this.IsSubAccount = false;
        }
      }
    } else {
      this.IsSubAccount = false;
    }
    if (this.$route.query.qrcode) {
      this.isSign = true;
      let str = this.$route.query.qrcode;
      str = str.replace("http", "https");
      for (let key in this.$route.query) {
        if (key != "qrcode" && key != "prop") {
          str += "&" + key + "=" + this.$route.query[key];
        }
      }
      this.getHref(str);
    }

    if (this.$route.query.phone) {
      this.uPhone = this.$route.query.phone;
    }

    if (this.$route.query.prop) {
      let arr = this.$route.query.prop.split(",");
      this.billid = arr[0];
      console.log("获取模板ididididi", this.ContentId, this.$route.query);
      if (this.$route.query.ContentId) {
        this.ContentId = this.$route.query.ContentId;
        await this.getFormData(true);
      } else {
        await this.getFormData(false);
      }

      await this.getGetQrCodeTmpList();

      this.codeValue = "https://shq.yoojet.com/#/SendSMSMobile?id=" + this.billid + "&type=0"

      // location.href.split("#")[0] +
      // `#/SendSMS?prop=${this.billid}&qrcode=${this.codeTwo}&ContentId=${this.ContentId}`;
    }

    console.log("created里判断路由", this.$route.query);

    this.getShareInfo();
  },

  methods: {
    toThousands,
    // 获取微信认证
    async getShareInfo() {
      // let newHref = location.href.replace('SendSMS','SendSMSShare')

      let url = location.href.split("#")[0]; // 只需要传地址就好，其他后端都会写好来
      console.log("打印大一location.href11", location.href);
      console.log("打印大一location.href22", url);
      let ticketData = await this.$http.get(
        "/BO/BillList/GetWxSign?url=" + url
        // "/BO/BillList/GetWxSign?url=https://shq.yoojet.com/"
      );
      let arr;
      if (ticketData.Data.length !== 0) {
        arr = ticketData.Data.split(",");
      }

      const config = {
        appId: "wxde354ac424a66adc",
        // appId: "wxa24101ce1f340e47",
        timestamp: arr[2],
        nonceStr: arr[1],
        signature: arr[0],
      };
      wx.config({
        debug: false, //是否打开debug调试模式。
        appId: config.appId, // appID 公众号的唯一标识
        timestamp: config.timestamp, // 生成签名的时间戳
        nonceStr: config.nonceStr, //  生成签名的随机串
        signature: config.signature, // 生成的签名
        jsApiList: ["onMenuShareAppMessage"],
      });
      wx.ready(() => {
        var shareData = {
          title: "码上签",
          desc: this.sendMessage,
          link: `http://shq.yoojet.com/#/SendSMSShareShow?prop=${this.billid}&qrcode=${this.codeTwo}&ContentId=${this.ContentId}&newSignType=${this.SignType}`,
          imgUrl: "https://shq.yoojet.com/wx.png",
          success: function (res) {
            console.log("分享好友成功");
          },
          cancel: function (err) {
            console.log(err, "错误信息");
          },
        };
        //点击要去分享
        wx.onMenuShareAppMessage(shareData);
      });
    },
    openwx() {
      console.log(1);
      window.open();
    },
    // 切换模板
    clickTemplate(item) {
      //修改默认模板
      this.$http.post("/Base/Base_Template/SetTemplateDefByH5", {
        Id: item.Id,
        CompanyId: item.CompanyId,
      });
      this.templateN = item;
      // this.showEmpty = true;

      this.ContentId = item.Id;
      this.ContentName = item.TemName;
      this.istemplate = false;
      // console.log(item, '选中item')
      let content = JSON.parse(item.Content);
      var Narr = content.printlist;
      var Larr = JSON.parse(this.Content).printlist;
      Narr.forEach((e) => {
        if (e.Keys == "table") {
          if (this.Status == "1") {
            //已签收 添加 签收统计 列
            this.addSignOverForTable(e);
          }
        }
      });
      Larr.forEach((e) => {
        if (e.Keys == "table") {
          if (this.Status == "1") {
            //已签收 添加 签收统计 列
            this.addSignOverForTable(e);
          }
        }
      });

      // this.jsoncontent = { ...JSON.parse(content) };
      var Narr2 = Narr.filter((item) => {
        return !item.ispringt;
      });
      var Larr2 = Larr.filter((item) => {
        return !item.ispringt;
      });
      Narr2.sort((a, b) => a.index - b.index);
      Larr2.sort((a, b) => a.index - b.index);

      let NtableDataList = Narr.find((item) => {
        return item.type === "4";
      });
      let LtableDataList = Larr.find((item) => {
        return item.type === "4";
      });
      if (NtableDataList && LtableDataList) {
        NtableDataList.datalist = [...LtableDataList.datalist];
      }

      var logUrl = Narr.find((item) => {
        return item.type === "3";
      });
      if (logUrl && logUrl.value) {
        this.getImgBase64DataBySingle([logUrl.value]);
      }
      Narr2.forEach((item) => {
        let obj = Larr2.find((val) => {
          return val.Keys === item.Keys;
        });
        if (obj) {
          this.$set(item, "value", obj.value);
        }
      });
      Narr.forEach((item) => {
        if (item.type === "4") {
          item = NtableDataList ? { ...NtableDataList } : item;
        }
      });
      content.printlist = [...Narr];
      this.changeTemplate(content);
    },
    async getTemplateList() {
      this.TemplateLoading = true;
      await this.$http
        .post("/Base/Base_Template/GetTemplateDataList", {
          Search: {
            CompanyId: this.Company,
          },
        })
        .then((res) => {
          // console.log("获取模板接口结果---", res.Data);
          if (res.Success) {
            this.template = res.Data;
          } else {
            this.template = [];
          }
          this.TemplateLoading = false;
        });
    },
    getHref(QrCode) {
      console.log(this.billid, "------------------------------------");
      //暂时注释  后续需要直接打开
      // this.href = `https://mashangqian.yoojet.com/#/?Id=${this.$route.query.prop}`;
      this.$http
        .post("/BO/BillList/GetBillLink", {
          id: QrCode,
        })
        .then((result) => {
          if (result.Success) {
            console.log("判断路由", this.$route.query, this.href);
            this.href = result.Data;
          }
        });
    },
    // 求和函数
    sumObjects(arr, key) {
      return arr.reduce(
        (sum, current) => sum + parseFloat(current[key] || 0),
        0
      );
    },
    changeTemplate(obj) {
      this.loading = true;
      this.printPages = obj.printPages;
      this.styleW = obj.styleW;
      this.styleH = obj.styleH;
      this.printlist = obj.printlist;
      this.printsize = obj.printsize;
      this.isAccessory = obj.isAccessory;
      this.showEmpty = obj.showEmpty;
      if (this.printsize === "portrait") {
        this.styleText =
          '<style type="text/css" media="print">\n' +
          "  @page { size: portrait; }\n" +
          "</style>";
      } else {
        this.styleText =
          '<style type="text/css" media="print">\n' +
          "  @page { size: landscape; }\n" +
          "</style>";
      }
      this.data = this.printlist.filter((item) => {
        return item.type === "4";
      }) || []
      var tablearr = JSON.parse(JSON.stringify(this.data));
      console.log(tablearr)
      console.log(this.data)
      var arr = [];
      if (tablearr[0]) {
        if (tablearr[0].rowCount) {
          for (
            var i = 0;
            i < tablearr[0].datalist.length;
            i += tablearr[0].rowCount
          ) {
            arr.push(tablearr[0].datalist.slice(i, i + tablearr[0].rowCount));
          }
        } else {
          if (obj.keyType === "A4") {
            for (var i = 0; i < tablearr[0].datalist.length; i += 10) {
              arr.push(tablearr[0].datalist.slice(i, i + 10));
            }
          } else {
            for (var i = 0; i < tablearr[0].datalist.length; i += 5) {
              arr.push(tablearr[0].datalist.slice(i, i + 5));
            }
          }
        }
      }


      var newarr = [];
      this.oldDatalist = [];
      arr.forEach((item) => {
        var newobj = JSON.parse(JSON.stringify({ ...obj }));
        newobj.printlist.forEach((val) => {
          if (val.Key == "发货日期" && val.value) {
            this.cTime = val.value;
          }
          if (val.type === "4") {
            val.datalist = [];
            this.$set(val, "datalist", item);
            let length = val.datalist.length;
            this.oldDatalist.push(JSON.parse(JSON.stringify(val.datalist)));
            if (val.rowCount) {
              if (length < val.rowCount) {
                for (let i = 0; i < val.rowCount - length; i++) {
                  if (!this.showEmpty) {
                    val.datalist.push({});
                  }
                }
              }
            } else {
              if (obj.keyType === "A4") {
                if (length < 10) {
                  for (let i = 0; i < 10 - length; i++) {
                    if (!this.showEmpty) {
                      val.datalist.push({});
                    }
                  }
                }
              } else {
                if (length < 5) {
                  for (let i = 0; i < 5 - length; i++) {
                    if (!this.showEmpty) {
                      val.datalist.push({});
                    }
                  }
                }
              }
            }
            this.hasEmptyList = JSON.parse(JSON.stringify(val.datalist));
          }
        });
        newarr.push(newobj);
      });
      this.printPages = arr.length;
      this.newarr = newarr;
      console.log("赋值表格数据---------------------------------", this.newarr);
      this.loading = false;
      this.codeValue = "https://shq.yoojet.com/#/SendSMSMobile?id=" + this.billid + "&type=0"
      // this.codeValue =
      //   location.href.split("#")[0] +
      //   `#/SendSMS?prop=${this.billid}&qrcode=${this.codeTwo}&ContentId=${this.ContentId}`;
    },
    // 客户手机号
    PhoneConfirm() {
      if (!regExp.test(this.phone))
        return this.$message.error("请填写格式正确的手机号");
      this.sendSMS();
      this.PhoneVisible = false;
    },
    // 确认下发
    confirmForm() {
      this.$http
        .post("/BO/BillList/AddGeneration?Id=" + this.billid)
        .then((res) => {
          if (res.Success) {
            this.getFormData();
            this.$message.success("操作成功");
          } else {
            this.$message.error(res.Msg);
          }
        });
    },
    // 编辑功能
    openEditForm() {
      this.$refs.waybillForm.openForm(this.entity, "1", this.Company, "");
    },
    // 身份证加密
    idCardPass(idCard) {
      if (idCard) {
        let a = idCard.split("");
        a.splice(6, 8, "********");
        return a.join("");
      } else {
        return "";
      }
    },
    // 下载存证
    creditFile() {
      this.$http
        .post("/BO/BillList/GetVisaPDF?id=" + this.billid)
        .then((res) => {
          if (res.Success) {
            downloadFile(this.$baseUrl + "/" + res.Data);
          }
        });
    },
    // 微信分享
    handleCommand(command) {
      // let baseUrl = location.href.split("#")[0];
      // let baseUrl = "https://hzq.yoojet.com/";
      let baseUrl = "https://shq.yoojet.com/";
      if (command == "单据信息") {
        this.codeValue =
          baseUrl + "#/SendSMSMobile?id=" + this.billid + "&type=0";
      } else {
        this.codeValue =
          baseUrl + "#/SendSMSMobile?id=" + this.billid + "&type=1";
      }
      this.dialogVisible = true;
    },
    datalistchange(a) {
      this.pagination.PageIndex = 1;
      this.getPrintBillListDetail(this.arrprint);
    },
    handleSizeChange(a, b) {
      this.pagination.PageRows = a;
      this.getPrintBillListDetail(this.arrprint);
    },
    handleCurrentChange(a, b) {
      this.pagination.PageIndex = a;
      this.getPrintBillListDetail(this.arrprint);
    },
    butprintclick() {
      if (this.templatelist.length === 0) {
        return this.$message.warning("请设置货品信息打印模板后打印");
      }
    },
    qrcodetext(id, arr) {
      var str = "";
      arr.forEach((item) => {
        if (item.Id === id) {
          str = item.value;
        }
      });
      return str;
    },
    getGetBillldPrintDetail(arr) {
      this.drawerloading = true;
      this.$http
        .post("/BO/BillList/GetBillldPrintDetail", {
          Search: {
            Id: this.billid,
            BarCodeList: arr,
            List: this.data[0].datalist,
          },
        })
        .then((res) => {
          if (res.Success) {
            this.printhuopinlist = res.Data;
          }
          setTimeout(() => {
            this.drawerloading = false;
          }, 100);
        });
    },
    dropdownclick(item) {
      this.cInvStd = "";
      this.arrprint = [];
      var obj = JSON.parse(item.Content);
      this.printopenobj = { ...obj };

      var arr = obj.printlist.filter((item) => {
        return item.Keys === "qrcode" || item.Keys === "barcode";
      });
      this.arrprint = arr;
      this.dialogGoods = true;
      this.cInvStd = this.data[0].datalist[0].Id;
      this.getPrintBillListDetail(arr);
    },
    // 隐藏/显示空行
    handleShowEmpty(e) {
      let y = 0;
      let length1 = 0;
      let length2 = 0;
      this.newarr.forEach((i, index) => {
        if (index == this.newarr.length - 1) {
          i.printlist.forEach((item) => {
            if (item.type == 4) {
              length1 = item.datalist.length;
            }
          });
        }
      });
      this.showEmpty = e;
      this.clickTemplate(this.templateN);
      this.newarr.forEach((i, index) => {
        if (index == this.newarr.length - 1) {
          i.printlist.forEach((item) => {
            if (item.type == 4) {
              y = item.y;
              length2 = item.datalist.length;
            }
          });
        }
      });
      this.newarr.forEach((i, index) => {
        if (index == this.newarr.length - 1) {
          i.printlist.forEach((item) => {
            if (item.y > y) {
              if (e) {
              } else {
                item.y -= 40 * Math.abs(length1 - length2);
              }
            }
          });
        }
      });
    },
    getPrintBillListDetail(arr) {
      var obj = this.data[0].datalist.find((item) => {
        return item.Id === this.cInvStd;
      });
      var objcopy = { ...obj };
      objcopy.BillId = this.billid;
      objcopy.BarCodeList = arr;

      this.$http
        .post("/BO/BillList/PrintBillListDetail", {
          PageIndex: this.pagination.PageIndex,
          PageRows: this.pagination.PageRows,
          Search: objcopy,
        })
        .then((res) => {
          if (res.Success) {
            this.printhuopinlist = res.Data;
            this.pagination.total = res.Total;
          } else {
            this.$message.error(res.Msg);
          }
        });
    },
    async getGetQrCodeTmpList() {
      await this.$http
        .post("/Base/Base_Template/GetQrCodeTmpList?Id=" + this.billid, {})
        .then((res) => {
          this.templatelist = res.Data;
        });
    },
    handleDownload() {
      let userPhone = this.uPhone;
      if (!this.uPhone) {
        userPhone = JSON.parse(sessionStorage.getItem("userInfo")).UserPhone;
      }

      if (pdfapis.includes(userPhone)) {
        this.pdfLoading = true;
        this.$http
          .post("/BO/BillList/GetPDF?id=" + this.billid)
          .then((res) => {
            if (res.Success) {
              downloadFile(res.Data, this.CustName + "-" + this.danhao);
            } else {
              this.$message.error(res.Msg);
            }
          })
          .finally((res) => {
            this.pdfLoading = false;
          });
      } else {
        this.$message.success("正在生成pdf, 请稍后");
        downloadPDF(this.$refs.pdfContent, this.CustName + "-" + this.danhao);
      }
    },
    getpx(num, wh, str) {
      var dpi = jsgetDPI();
      if (str == "px") {
        if (wh == "w") {
          return (num / 2.54) * dpi[0];
        }
        if (wh == "h") {
          return (num / 2.54) * dpi[1];
        }
      }
      if (str == "cm") {
        if (wh == "w") {
          return (num / dpi[0]) * 2.54;
        }
        if (wh == "h") {
          return (num / dpi[1]) * 2.54;
        }
      }
      // px转cm：cm = pxValue/dpi*2.54
      //           px = cm/2.54*dpi
    },
    async getqrcodemodel() {
      await this.$http
        .post(
          `/Base/Base_Template/GetCompanyTemp?CompayId=${this.Company}&type=1`,
          {}
        )
        .then((res) => {
          if (res.Success) {
            this.qrcodemodel = res.Data;
          } else {
            this.$message.error(res.Msg);
          }
        });
    },
    async getbarcodemodel() {
      await this.$http
        .post(
          `/Base/Base_Template/GetCompanyTemp?CompayId=${this.Company}&type=2`,
          {}
        )
        .then((res) => {
          if (res.Success) {
            this.barcodemodel = res.Data;
          } else {
            this.$message.error(res.Msg);
          }
        });
    },
    // 货品打印
    async command(command) {
      if (command.split("-")[1] === "1") {
        //二维码
        await this.getqrcodemodel();
      } else if (command.split("-")[1] === "0") {
        //条形码
        await this.getbarcodemodel();
      }
      this.printId = command;
      this.GoodsPrint();
    },
    // 生成打印数据
    printChange(e) {
      let print = this.printData[e];
      // 开始结束初始值
      let Begin = print.Begin;
      let End =
        print.Nums > this.pagination.pageSize
          ? this.pagination.pageSize
          : print.End;
      // 分页赋值
      this.pagination.total = print.Nums;
      // 数据
      let arr = [];
      for (let i = Begin; i <= End; i++) {
        arr.push({
          Name: print.cInvName,
          QrCode: print.QrCode + "-" + (i + "").padStart(6, "0"),
        });
      }
      this.showPrintData = arr;
    },
    currentChange(page) {
      this.pagination.current = page;
      let print = this.printData[this.printValue];
      let Begin = print.Begin + (page - 1) * this.pagination.pageSize;
      let End =
        print.Nums > page * this.pagination.pageSize
          ? page * this.pagination.pageSize
          : print.End;
      // 数据
      let arr = [];
      for (let i = Begin; i <= End; i++) {
        arr.push({
          Name: print.cInvName,
          QrCode: print.QrCode + "-" + (i + "").padStart(6, "0"),
        });
      }
      this.showPrintData = arr;
    },
    GoodsPrint() {
      if (!this.printId) return this.$message.error("请选择打印模式");
      this.getPrintData(this.printId.split("-")[0], this.printId.split("-")[1]);
      this.dialogPrintVisible = false;
    },
    getPrintData(e, qrendbar) {
      this.qrendbar = "";
      this.qrendbar = qrendbar;
      this.printShowValue = e;

      if (qrendbar === "1") {
        this.printopenobj = JSON.parse(this.qrcodemodel.Content);
        if (e === "1") {
          //等于客户
          this.printopenlist = this.printopenobj.printlist.filter((item) => {
            return item.Key !== "平台二维码" && item.Key !== "国际二维码";
          });
        } else if (e === "0") {
          //等于自我
          this.printopenlist = this.printopenobj.printlist.filter((item) => {
            return item.Key !== "客户二维码" && item.Key !== "国际二维码";
          });
        } else if (e === "2") {
          //等于全部
          this.printopenlist = this.printopenobj.printlist;
        }
      } else if (qrendbar === "0") {
        this.printopenobj = JSON.parse(this.barcodemodel.Content);
        if (e === "1") {
          //等于客户
          this.printopenlist = this.printopenobj.printlist.filter((item) => {
            return item.Key !== "平台条形码" && item.Key !== "国际条形码";
          });
        } else if (e === "0") {
          //等于自我
          this.printopenlist = this.printopenobj.printlist.filter((item) => {
            return item.Key !== "客户条形码" && item.Key !== "国际条形码";
          });
        } else if (e === "2") {
          //等于全部
          this.printopenlist = this.printopenobj.printlist;
        }
      }
      this.printData = this.data[0].datalist;
      this.dialogGoods = true;
    },
    handleClose() {
      this.dialogVisible = false;
      this.dialogGoods = false;
    },
    creatQrCode(text) {
      if (this.$refs.qrCodeUrl) return;
      this.$nextTick(() => {
        var qrcode = new QRCode(this.$refs.qrCodeUrl, {
          text, // 需要转换为二维码的内容
          width: 200,
          height: 200,
          colorDark: "#000000",
          colorLight: "#ffffff",
          correctLevel: QRCode.CorrectLevel.H,
        });
      });
    },
    async getFormData(isH5 = false) {
      await this.$http
        .post("/BO/BillList/GetBillDetailByGreen", { id: this.billid })
        .then(async (res) => {
          if (res.Success) {
            let newObj = JSON.parse(JSON.stringify(res.Data));
            newObj.content = "";
            newObj.Content = "";
            localStorage.setItem("sendRowData", JSON.stringify(newObj));
            console.log("获取查看页面详情------", res.Data);
            this.CompanyName = res.Data.CompanyName;
            this.CustName = res.Data.CustomerName;
            this.TotalSum = toThousands(res.Data.TotalSum);
            this.SignCode = res.Data.SignCode;
            this.codeTwo = res.Data.QrCode;
            this.danhao = res.Data.BillId;
            this.smsCode = res.Data.SmsCode;
            this.Sign = res.Data.Sign;
            this.Sign1 = res.Data.Sign1;
            this.Seal = res.Data.Seal;
            this.SignType = res.Data.SignType;
            this.SendType = res.Data.SendType ? res.Data.SendType : "";
            // 获取微信小程序短链需要的参数
            this.QrCode = res.Data.QrCode;
            this.countnums = res.Data.countnums;
            this.wights = res.Data.wights;
            this.sendMessage = res.Data.SendMessage;
            this.CustomerId = res.Data.CustomerId;
            this.phone = res.Data.CustomerPhone;
            this.Company = res.Data.Company;
            this.IdCard = res.Data.IdCard;
            this.Memo = res.Data.Memo;
            // this.ContentId = res.Data.TemplateId;
            this.ReceiptPhone = res.Data.ReceiptPhone;
            this.FullName = res.Data.FullName;
            this.SigningDate = res.Data.SigningDate;
            this.timeList = JSON.parse(res.Data.OrderLog);
            this.Status = res.Data.Status; // 订单状态
            this.entity = res.Data;
            this.videoSrc = res.Data.Video;

            this.cTime = res.Data.CreateTime?.substring(0, 10);
            if (res.Data.Content) {
              this.Content = res.Data.Content;
              const content = JSON.parse(this.Content);
              // 这里只可进行取值，后边会重新编辑！！！
              if (content.printlist && content.printlist.length) {
                content.printlist.forEach((val) => {
                  if (val.Keys == "totalweights") {
                    this.totalweights = val.value || "";
                  }
                  if (val.Keys == "totalnumbers") {
                    this.totalnumbers = val.value || "";
                  }
                  if (val.Keys == "Memo") {
                    if (val.value) {
                      this.Memo = val.value;
                    }
                  }
                });
              }
              if (res.Data.Imgs) {
                let _list = res.Data.Imgs.split(",");
                this.getImgBase64Data(_list);
                //this.imglist = res.Data.Imgs.split(",");
              } else {
                this.imglist = [];
              }
              await this.getTemplateList();
              let changeObj = this.template.find((item) => {
                return item.Id === this.ContentId;
              });
              this.templateN = changeObj;
              // console.log(
              //   "获取模板详情是否有this.templatethis.templatethis.templatethis.templatethis.template",
              //   this.ContentId,
              //   this.template
              // );
              if (isH5 && changeObj) {
                // console.log(
                //   "是否走这里了走的是切换切换切换切换切换切换切换切换模板",
                //   isH5,
                //   changeObj,
                //   this.template
                // );
                this.$nextTick(() => {
                  this.clickTemplate(changeObj);
                });
              } else {
                let InitialtemplateArr = this.template.filter(
                  (item) => item.Initialtemplate == true
                )[0];
                // console.log(
                //   "走的是这里了没没没没没没切换模板",
                //   res.Data,
                //   this.template,
                //   InitialtemplateArr
                // );
                this.$nextTick(() => {
                  // 判断返回的模板列表里是否存在Initialtemplate为true 的使用true的  否则使用详情返回来的

                  if (InitialtemplateArr) {
                    this.clickTemplate(InitialtemplateArr);
                  } else {
                    var obj = JSON.parse(res.Data.Content);
                    this.printPages = obj.printPages;
                    this.styleW = obj.styleW;
                    this.styleH = obj.styleH;
                    this.printlist = obj.printlist.forEach((e) => {
                      if (e.Keys == "table") {
                        if (this.Status == "1") {
                          //已签收 添加 签收统计 列
                          this.addSignOverForTable(e);
                        }
                      }
                    });
                    this.printlist = obj.printlist;
                    this.printsize = obj.printsize;
                    this.isAccessory = obj.isAccessory;
                    this.showEmpty = obj.showEmpty;
                    if (this.printsize === "portrait") {
                      this.styleText =
                        '<style type="text/css" media="print">\n' +
                        "  @page { size: portrait; }\n" +
                        "</style>";
                    } else {
                      this.styleText =
                        '<style type="text/css" media="print">\n' +
                        "  @page { size: landscape; }\n" +
                        "</style>";
                    }
                    this.data = this.printlist.filter((item) => {
                      return item.type === "4";
                    });
                    var logUrl = this.printlist.filter((item) => {
                      return item.type === "3";
                    });
                    // 获取头像图片转码
                    if (logUrl && logUrl.length && logUrl[0].value) {
                      this.getImgBase64DataBySingle([logUrl[0].value]);
                    }
                    //判断签收人是否有多个
                    let isnewArr = this.printlist.filter((item) => {
                      return item.type === "5";
                    });
                    if (isnewArr.length > 1) {
                      this.isnew = true;
                    } else {
                      this.isnew = false;
                    }
                    var tablearr = JSON.parse(JSON.stringify(this.data));
                    var arr = [];
                    if (tablearr[0].rowCount) {
                      for (
                        var i = 0;
                        i < tablearr[0].datalist.length;
                        i += tablearr[0].rowCount
                      ) {
                        arr.push(
                          tablearr[0].datalist.slice(
                            i,
                            i + tablearr[0].rowCount
                          )
                        );
                      }
                    } else {
                      if (obj.keyType === "A4") {
                        for (
                          var i = 0;
                          i < tablearr[0].datalist.length;
                          i += 10
                        ) {
                          arr.push(tablearr[0].datalist.slice(i, i + 10));
                        }
                      } else {
                        for (
                          var i = 0;
                          i < tablearr[0].datalist.length;
                          i += 5
                        ) {
                          arr.push(tablearr[0].datalist.slice(i, i + 5));
                        }
                      }
                    }

                    var newarr = [];
                    this.oldDatalist = [];
                    arr.forEach((item) => {
                      var newobj = JSON.parse(res.Data.Content);
                      newobj.printlist.forEach((val) => {
                        if (val.Key == "发货日期" && val.value) {
                          this.cTime = val.value;
                        }
                        if (val.type === "4") {
                          val.datalist = [];
                          this.$set(val, "datalist", item);
                          let length = val.datalist.length;
                          this.oldDatalist.push(
                            JSON.parse(JSON.stringify(val.datalist))
                          );
                          if (val.rowCount) {
                            if (length < val.rowCount) {
                              for (let i = 0; i < val.rowCount - length; i++) {
                                if (!this.showEmpty) {
                                  val.datalist.push({});
                                }
                              }
                            }
                          } else {
                            if (obj.keyType === "A4") {
                              if (length < 10) {
                                for (let i = 0; i < 10 - length; i++) {
                                  if (!this.showEmpty) {
                                    val.datalist.push({});
                                  }
                                }
                              }
                            } else {
                              if (length < 5) {
                                for (let i = 0; i < 5 - length; i++) {
                                  if (!this.showEmpty) {
                                    val.datalist.push({});
                                  }
                                }
                              }
                            }
                          }
                          this.hasEmptyList = JSON.parse(
                            JSON.stringify(val.datalist)
                          );
                        }
                      });
                      newarr.push(newobj);
                    });
                    this.printPages = arr.length;
                    this.newarr = newarr;
                  }
                  this.newarr.forEach((item) => {
                    item.printlist.forEach((v) => {
                      if (v.Keys === "table") {
                        this.sumTotal = this.sumObjects(
                          v.datalist,
                          "SumiInvRCost"
                        );
                      }
                      if (v.Keys === "DiscountAmount") {
                        v.value = toThousands(this.sumTotal);
                      }
                    });
                  });
                });
              }

              this.$nextTick(() => {
                setTimeout(() => {
                  this.isMobiles && pc2phone("printDiv");
                });
              });
            } else {
              this.$message.error("暂无信息");
            }
            this.loading = false;
          } else {
            this.$message.error("数据获取失败!请刷新页面重试");
          }
        });
    },
    getImgBase64Data(list) {
      this.$http
        .post("/Base_Manage/Upload/ImageUrlToBase64", list)
        .then((res) => {
          if (res.Data && res.Data.length) {
            this.imglist = res.Data;
          }
        });
    },
    getImgBase64DataBySingle(list) {
      this.$http
        .post("/Base_Manage/Upload/ImageUrlToBase64", list)
        .then((res) => {
          if (res.Data && res.Data.length) {
            this.logo64 = res.Data[0];
          }
        });
    },
    resetValue(val, itemdata) {
      if (itemdata[val.dataIndex]) {
        if (val.type === "text") {
          return itemdata[val.dataIndex];
        } else if (val.type === "number") {
          return this.NumFormatting(
            itemdata[val.dataIndex],
            Number(val.decimals ? val.decimals : 2)
          );
        } else {
          return this.NumFormatting(itemdata[val.dataIndex], 2);
        }
      } else {
        return "";
      }
    },
    NumFormatting(data, num = 2) {
      return NumFormatting(data, num);
    },
    // 实收重量
    signweights(list) {
      var arr = list.filter((item) => {
        return item.type === "4";
      });
      var num = 0;

      let row = arr[0].columns.find((item) => {
        return item.dataIndex == "Weight";
      });
      if (row) {
        // 如果有重量的话 实收数量就是实收数量乘以重量
        // 没有的话就是总重除以件数再乘以签收数量
        arr[0].datalist.forEach((item) => {
          num += Number(item.nums || 0) * Number(item.Weight || 0);
        });
      } else {
        arr[0].datalist.forEach((item) => {
          num +=
            ((Number(item.Weight1) || 0) / (Number(item.Quantity) || 0)) *
            (Number(item.nums) || 0);
        });
      }
      row = row || {};
      if (row.type === "number") {
        num = Number(num).toFixed(row.decimals ? row.decimals : 2);
      } else if (row.type === "text") {
        num = num;
      } else {
        num = this.NumFormatting(num);
      }
      return num;
    },
    // 实收数量
    realNums(list) {
      var arr = list.filter((item) => {
        return item.type === "4";
      });
      var num = 0;
      arr[0].datalist.forEach((item) => {
        if (item.nums) {
          num += Number(item.nums);
        }
      });

      let row = arr[0].columns.find((item) => {
        return item.dataIndex == "Quantity";
      });
      row = row || {};
      if (row.type === "number") {
        num = Number(num).toFixed(row.decimals ? row.decimals : 2);
      } else if (row.type === "text") {
        num = num;
      } else {
        num = this.NumFormatting(num);
      }
      return num;
    },
    // 金额合计 单页
    priceNums(list) {
      var arr = list.filter((item) => {
        return item.type === "4";
      });

      var num = 0;
      let row = arr[0].columns.find((item) => {
        return item.dataIndex == "SumiInvRCost";
      });
      if (row) {
        arr[0].datalist.forEach((item) => {
          // 如果有金额的话就想加金额
          // 没有的金额的话就单价乘以件数
          num += Number(item.SumiInvRCost) || 0;
        });
      } else {
        arr[0].datalist.forEach((item) => {
          // 如果有金额的话就想加金额
          // 没有的金额的话就单价乘以件数
          num += (Number(item.iInvRCost) || 0) * (Number(item.Quantity) || 0);
        });
      }
      row = row || {};
      if (row.type === "number") {
        num = Number(num).toFixed(row.decimals ? row.decimals : 2);
      } else if (row.type === "text") {
        num = num;
      } else {
        num = this.NumFormatting(num);
      }
      var _num = Number(num).toFixed(2);
      return _num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    // 金额总计
    priceNumsAll() {
      let num = 0;
      this.newarr.forEach((item) => {
        num += this.priceNums(item.printlist).replace(/,/g, "");
      });

      var _num = parseFloat(num).toFixed(2);
      return _num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    wightsfun(list) {
      var arr = list.filter((item) => {
        return item.type === "4";
      });
      var num = 0;

      let row = arr[0].columns.find((item) => {
        return item.dataIndex == "Weight1";
      });
      if (row) {
        arr[0].datalist.forEach((item) => {
          if (item.Weight1) {
            num = Number(item.Weight1) + num;
          }
        });
      } else {
        arr[0].datalist.forEach((item) => {
          // 如果有总重的话就想加
          // 没有的话就重量乘以件数
          num += (Number(item.Weight) || 0) * (Number(item.Quantity) || 0);
        });
      }
      row = row || {};
      if (row.type === "number") {
        num = Number(num).toFixed(row.decimals ? row.decimals : 2);
      } else if (row.type === "text") {
        num = num;
      } else {
        num = this.NumFormatting(num);
      }
      return num;
    },

    subtotalweightsHandle(list, index) {
      let num = 0;
      let table = list.filter((e) => e.Keys === "table");
      if (table.length) {
        let list = table[0].datalist;
        if (list && list.length) {
          list.forEach((item) => {
            num += Number(item.xj2 || 0);
          });
        }
      }
      return num.toFixed(1);
    },
    subtotalnumsHandle(list, index) {
      let num = 0;
      let table = list.filter((e) => e.Keys === "table");
      if (table.length) {
        let list = table[0].datalist;
        if (list && list.length) {
          list.forEach((item) => {
            num += Number(item.xj1 || 0);
          });
        }
      }
      return num.toFixed(1);
    },

    countnumsfun(list) {
      var arr = list.filter((item) => {
        return item.type === "4";
      });
      var num = 0;
      arr[0].datalist.forEach((item) => {
        if (item.Quantity) {
          num = Number(item.Quantity) + num;
        }
      });
      let row = arr[0].columns.find((item) => {
        return item.dataIndex == "Quantity";
      });
      row = row || {};
      if (row.type === "number") {
        num = Number(num).toFixed(row.decimals ? row.decimals : 2);
      } else if (row.type === "text") {
        num = num;
      } else {
        num = this.NumFormatting(num);
      }
      return num;
    },
    // 发送签收链接和发送签收验证码
    sendSMS(type) {

      console.log("发送验证码了", this.phone, type);

      let sendRowData = JSON.parse(localStorage.getItem("sendRowData"));
      console.log("获取缓存行数据", sendRowData);
      console.log("参数", this.billid, this.phone);
      if (!this.phone) return (this.PhoneVisible = true);
      if (type === "发送签收验证码") {
        this.$http
          .post(
            `/BO/CustomerLinkman/CSSendSMS?Id=${this.billid}&Phone=${this.phone}`
          )
          .then((res) => {
            if (res.Success) {
              this.SignCode = res.Data;
              this.$message.success("短信发送成功");
              clearInterval(this.timer);
              this.disabled = true;
              this.timer = setInterval(() => {
                this.oneMinute--;
                if (this.oneMinute <= 0) {
                  clearInterval(this.timer);
                  this.oneMinute = 60;
                  this.disabled = false;
                }
              }, 1000);
            } else {
              this.$alert(res.Msg, "提示", {
                confirmButtonText: "确定",
              });
            }
          });
      } else {
        let sendRowData = JSON.parse(localStorage.getItem("sendRowData"));
        console.log("获取缓存行数据", sendRowData);
        sendRowData.CustomerPhone = this.phone;
        this.$http
          .post(`/BO/BillList/BatchReminBill?DomainName=shq`, [sendRowData])
          .then((res) => {
            if (res.Success) {
              this.SignCode = res.Data;
              this.$message.success("签收链接发送成功");
              clearInterval(this.timer);
              this.urlDisabled = true;
              this.timer = setInterval(() => {
                this.twoMinute--;
                if (this.twoMinute <= 0) {
                  clearInterval(this.timer);
                  this.twoMinute = 60;
                  this.urlDisabled = false;
                }
              }, 1000);
            } else {
              this.$alert(res.Msg, "提示", {
                confirmButtonText: "确定",
              });
            }
          });
      }
    },
    timestampToTime(timestamp) {
      // 时间戳为10位需*1000，时间戳为13位不需乘1000
      var date = new Date(timestamp - 28800000);
      var Y = date.getFullYear() + "-";
      var M =
        (date.getMonth() + 1 < 10
          ? "0" + (date.getMonth() + 1)
          : date.getMonth() + 1) + "-";
      var D =
        (date.getDate() < 10 ? "0" + date.getDate() : date.getDate()) + " ";
      var h = date.getHours() + ":";
      var m = date.getMinutes() + ":";
      var s = date.getSeconds();
      return Y + M + D + h + m + s;
    },
    pagesizeSwitchChange(e) {
      if (this.pageScale == e) {
        return;
      }
      this.pageScale = e;
      this.handleShowBaseWidth(this.pageScale);
    },
    handleShowBaseWidth(e) {
      let el = document.getElementById("printDiv");
      if (!el) {
        return;
      }
      if (e) {
        pc2phone("printDiv");
      } else {
        el.style["transform"] = `scale(1)`;
      }
    },
    getTotalWeight() {
      if (this.totalweights) {
        return Number(this.totalweights).toFixed(1);
      }
      return 0;
    },
    getTotalNumber() {
      if (this.totalnumbers) {
        return Number(this.totalnumbers).toFixed(1);
      }
      return 0;
    },
    addSignOverForTable(val) {
      if (val.columns && val.columns.length) {
        let inx = val.columns.findIndex((e) => e.dataIndex === "nums");
        if (inx == -1) {
          val.columns.push({
            dataIndex: "nums",
            bold: "initial",
            decimals: 1,
            fontSize: 16,
            maxlength: 1000,
            newDataIndex: "nums",
            signShowColumn: "0",
            sortIndex: 1000,
            title: "签收数量",
            type: "text",
            width: 40,
          });
        }
      }
    },
  },
};
</script>

<style lang="less" scoped>
@page {
  size: auto;
  margin: 0mm !important;
}

@media print {
  * {
    -webkit-print-color-adjust: exact !important;
    -moz-print-color-adjust: exact !important;
    -ms-print-color-adjust: exact !important;
    print-color-adjust: exact !important;
  }
}

td {
  height: 24px;
}

.rightMain {
  line-height: 1.7;
  margin-bottom: 20px;
  box-sizing: border-box;
  padding-bottom: 10px;
  color: #000;
  border-bottom: 1px solid #ccc;
}

.printBox {
  background: #fff;
  border-top: 1px solid #ccc;
  padding: 10px 40px;
  text-align: left;
  position: fixed;
  // width: 960px;
  bottom: 0;
  left: 0;
}

.SginImgBox {
  display: flex;
  align-items: center;
  height: 80px;
  width: 120px;

  .SginImg {
    transform: rotate(-90deg);
    width: 80px;
  }
}

.barcode {
  width: 100%;
  height: 100%;
}

/deep/ .vue-barcode-element {
  width: 100%;
  height: 100%;
}

.sign {
  width: 100%;
  text-align: center;
}

.mobile-botton {
  padding: 10px;
  width: 100vw;

  .el-button+.el-button,
  .bottom-botton {
    margin-left: 5px !important;
    margin-bottom: 5px;
  }
}

.pctool {
  right: 10px;
  bottom: 80px;
  box-sizing: border-box;
  text-align: right;
  opacity: 0.4;
  position: fixed;
  width: 100px;
  display: flex;
}

.tool-button {
  width: 50px;
  background-color: #32cfaa;
  color: #fff;
  margin: 0;
  padding: 0;
  line-height: 32px;
  height: 32px;
  text-align: center;
}

.tool-button.left {
  border-radius: 0;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
}

.tool-button.right {
  border-radius: 0;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
}

.tool-icon {
  color: #fff;
  background-color: #32cfaa;
  border-color: #32cfaa;
}
</style>
